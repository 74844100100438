const idPortalApi = "ddcf0ac971af1df8f74c9b5b51e1f48dcd1f89d3";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://www.cmsaojoaodospatos.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmsaojoaodospatos.ma.gov.br",
  NomePortal: "São João dos Patos",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `https://www.youtube.com/@camaramunicipaldesjp`,
  link_localize: `https://maps.app.goo.gl/Bi33dPdNYcPXp8Vj7`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15856.858461710954!2d-43.7033316!3d-6.4944892!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7841b92bd44a493%3A0xb3abee6143d6c78f!2sS%C3%A3o%20Jo%C3%A3o%20dos%20Patos%2C%20MA%2C%2065665-000!5e0!3m2!1spt-BR!2sbr!4v1722134639789!5m2!1spt-BR!2sbr",
};
